import React from "react";
import {get} from "lodash";
import {graphql, useStaticQuery} from "gatsby";
import Helmet from "react-helmet";
import Section from "../components/section/section";
import styles from '../scss/page.scss';
import {Accordion as BootstrapAccordion, Card} from "react-bootstrap";
import Img from "gatsby-image/withIEPolyfill";
import Carousel from "react-bootstrap/Carousel";
import ReviewStars from "../components/review-stars/review-stars";
import TrustBox from "../components/trustpilot";
import ToC from "../components/table-of-contents/table-of-contents.js";
import Layout from "../components/layout";
import SEO from "../components/seo";


class GetStarted extends React.Component {
  constructor(props) {
    super(props);
    this.state = { posSticky: 0 };
    this.reviews =  [
      {
        content: 'The entire process was very efficient and less time-consuming. My therapist did a great job in assisting me with my case and explaining the details.',
        stars: 5
      },
      {
        content: 'I can’t stress enough how stress free the whole process was. Much easier than expected.',
        stars: 5
      },
      {
        content: 'Was connected with the perfect therapist and the process was very seamless. Took 3 days to receive my letter and by the end of the week my pet and I were travelling for Christmas.',
        stars: 5
      }

    ]
    this.goToElement = this.goToElement.bind(this)
    this.seo = {
      title: 'Get Your Legitimate Emotional Support Animal Letter',
      description:  'You can get a legitimate Emotional Support Animal Letter from the comfort of your home. Take our quick pre-screening to see if you qualify today!',
      seoTitle: 'Get Your Legitimate Emotional Support Animal Letter'
    }
  }
  componentDidMount() {
    const targetElementSticky = document.getElementById('sticky-bar')
    const stickyHeight = targetElementSticky.getBoundingClientRect().height
    this.setState(() => {
      return { posSticky: stickyHeight };
    });
  }
  /**
   * Smoothly scrolls down to element in page
   * @param  {Event} e
   * @return {void}
   */
  goToElement(e) {
    const { currentTarget } = e
    const id = (currentTarget.getAttribute('href') || '').replace('#', '')
    const targetElement = document.getElementById(id)
    if (targetElement) {
      e.preventDefault()
      const pos = targetElement.getBoundingClientRect()
      const targetTop = pos.top + window.scrollY - this.state.posSticky
      window.scroll({top: targetTop, left: 0, behavior: 'smooth' });
    }
  }

  render() {
    const { data, children } = this.props
    const ctaUrl = `${get(data, 'site.siteMetadata.certapetInfo.url.ctaUrl')}`
    return (
      <>
        <Layout containerClassNames="page get-started">
          <SEO { ...this.seo } />
          <Section bgColor="#e5f5fa" className="image-text-alternate px-0">
            <div className="row align-items-center">
              <div className="col-12 col-md-6">
                <Img fluid={data.youngWomanCheckingHerIpadWithHerEmotionalSupportDogByHerSide.childImageSharp.fluid} alt="Young woman checking her ipad with her emotional support dog by her side" className="img-fluid lazyload" />
              </div>
              <div className="col-12 col-md-6 mb-5">
                  <h2 className="col-12 col-md-12 mb-5 mt-5 mt-md-0 text-md-left text-center">Get Started Now With Your <br className='d-none d-md-block'/>Pre-Screening Evaluation</h2>

                  <div className="col-12 col-md-10">
                    <p className="text-md-left text-center">For less than $1 a Day you can get a <br className='d-none d-md-block'/>legitimate ESA Letter from Therapetic <br className='d-none d-md-block'/>from the comfort of your home. </p>
                    <br/>
                  </div>
                  <div className="col-12 col-md-12 my-4 text-md-left text-center">
                    <div className="call-button my-lg-5">
                      {/*<a href={`${ctaUrl}&utm_campaign=getstarted&utm_content=cta1`} target="_blank">Begin Your Free Screening</a>*/}
                      <a href="/screening">Begin Your Free Screening</a>
                    </div>
                  </div>
              </div>
            </div>
          </Section>

          <Section bgColor="#e8f4f4" className="section-cards py-md-5 px-0">
            <div className="row">
              <div className="col-10 col-md-10 text-center mt-5 mb-md-4 mx-auto">
                <h2>What is an ESA Letter?<br className='d-none d-md-block'/> What Does it Let You Do?</h2>
                <br/>
                <p>An Emotional Support Animal (ESA) is a pet that provides companionship to a<br className='d-none d-md-block'/> person who suffers from symptoms of a mental or emotional disability.</p>
                <br/>
                <div className="row mt-5 py-5">
                  <div className="col-12 col-md-4 text-center mb-5 mb-md-auto">
                    <Card>
                      <Card.Header className="rounded-circle number">
                        <Img fluid={data.icoBenefits.childImageSharp.fluid} alt="Benefit Icon" className="img-fluid lazyload" />
                      </Card.Header>
                      <Card.Body>
                        <Card.Title as="h3" className="mb-4 mt-3 px-2">The ability to live with your Emotional Support Animal in a rental property or housing community.</Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-12 col-md-4 text-center mb-5 mt-5 mb-md-auto mt-md-auto">
                    <Card>
                      <Card.Header className="rounded-circle number">
                        <Img fluid={data.icoBenefits.childImageSharp.fluid} alt="Benefit Icon" className="img-fluid lazyload" />
                      </Card.Header>
                      <Card.Body>
                        <Card.Title as="h3" className="mb-4 mt-3 px-2">You cannot be charged an extra fee when placing a deposit for a rental property.</Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-12 col-md-4 text-center mb-5 mt-5 mb-md-auto mt-md-auto">
                    <Card>
                      <Card.Header className="rounded-circle number">
                        <Img fluid={data.icoBenefits.childImageSharp.fluid} alt="Benefit Icon" className="img-fluid lazyload" />
                      </Card.Header>
                      <Card.Body>
                        <Card.Title as="h3" className="mb-4 mt-3 px-2">There are no pet fees when you fly with your Emotional Support Animal.</Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </Section>

          <Section bgColor="#f2f2f2" className="review">
            <div className="row">
              <div className="col-12 col-md-10 text-center my-5 mt-md-4 mx-auto">
                <h2>More Than 65,000 Americans Trust<br className='d-none d-md-block'/> Therapetics For Their ESA Letter Needs</h2>
                <br/>
                <p>We pride ourselves on providing support throughout the process.</p>
                <p>Which is why we’re #1 in the ESA Letter category.</p>
                <br/>
                <div className="row">
                  <div className="col-12 col-md-12 mt-5 mx-auto">
                    <Carousel className="carousel-reviews text-left d-block d-md-none" controls={false}>
                      { this.reviews.map((review, index) => {
                        return (
                          <Carousel.Item className="reviews-slide" key={index}>
                            <div className="row">
                              <div className="col-12 col-md-4 text-center mt-3">
                                <Card>
                                  <Card.Header>
                                    <Img fluid={data.blockquote.childImageSharp.fluid} alt="Review Icon" className="img-fluid lazyload" />
                                  </Card.Header>
                                  <Card.Body>
                                    <Card.Text>
                                      {review.content}
                                    </Card.Text>
                                    <ReviewStars stars={review.stars} color="#2dbecc" />
                                  </Card.Body>
                                </Card>
                              </div>
                            </div>
                          </Carousel.Item>
                        )
                      })}
                    </Carousel>

                    <Carousel className="carousel-reviews text-left d-none d-sm-none d-md-block" controls={false}>
                      <Carousel.Item className="reviews-slide">
                        <div className="row">
                          { this.reviews.map((review, index) => {
                            return (
                              <div className="col-12 col-md-4 text-center mt-3" key={index}>
                                <Card>
                                  <Card.Header>
                                    <Img fluid={data.blockquote.childImageSharp.fluid} alt="Review Icon" className="img-fluid lazyload" />
                                  </Card.Header>
                                  <Card.Body>
                                    <Card.Text>
                                      {review.content}
                                    </Card.Text>
                                    <ReviewStars stars={review.stars} color="#2dbecc" />
                                  </Card.Body>
                                </Card>
                              </div>
                            )
                          })}
                        </div>
                      </Carousel.Item>
                    </Carousel>
                  </div>

                  <div className="container">
                    <div className="row pt-5 mt-5">
                      <div className="col-12 col-md-12 mt-3 text-center mb-4">
                        <div className="call-button my-lg-5">
                          {/*<a href={`${ctaUrl}&utm_campaign=getstarted&utm_content=cta2`} target="_blank">Begin Your Free Screening</a>*/}
                          <a href="/screening">Begin Your Free Screening</a>
                        </div>
                        <div className="col-12">
                          <TrustBox templateId="5419b6a8b0d04a076446a9ad" businessUnitId="5a0093cb0000ff0005afb51b" theme="light" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Section>

          <Section bgColor="#eaf5fa" className="customize py-3 px-0">
            <div className="row">
              <div className="col-12 col-md-6 mx-auto mt-md-5">
                <Img fluid={data.youngMenAtTheParkWithHisEmotionalSupportAnimal.childImageSharp.fluid} alt="Young men at the park with his emotional support animal" className="img-fluid lazyload mt-md-5" />
              </div>
            </div>
            <div className="row">
              <div className="col-10 col-md-6 mx-auto mt-5 mb-5">
                <h2>An ESA Letter Will Help You With The <br className='d-none d-md-block'/>
                  Following Conditions</h2>
                <br/>
                <div className="row my-4">
                  <div className="col-md-6 col-sm-12">
                    <ul>
                      <li>Anxiet​y</li>
                      <li>Depression</li>
                      <li>Generalized Anxiety Disorder</li>
                      <li>Panic Disorder</li>
                      <li>Separation Anxiety</li>
                      <li>Mood Disorders</li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <ul>
                      <li>Personality Disorders</li>
                      <li>Stress</li>
                      <li>Seasonal Affective Disorder</li>
                      <li>Social Anxiety Disorder</li>
                      <li>Fear/Phobias</li>
                      <li>Post-Traumatic Stress Disorder</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Section>

          <Section bgColor="#e8f4f4" className="cards-left-number pb-5">
            <div className="row pb-5">
              <div className="col-12 col-md-6 text-center mt-5 mx-auto">
                <h2>Why Would You Use Therapetics?</h2>
                <br/>
              </div>
              <div className="col-12 col-md-10 text-center mt-5 mx-auto">
                <div className="row mt-5">

                  <div className="col-12 col-md-5 ml-md-5 text-center">
                    <div className="row">
                      <div className="col-12 col-md-5 img">
                        <Img fluid={data.easyProcessIcon.childImageSharp.fluid} alt="" className="img-fluid lazyload mt-md-4 mt-sm-1 ml-md-0 ml-5" />
                      </div>
                      <div className="col-12 col-md-7 text-left px-5">
                        <h3 className="mt-md-4 mt-3">Easy process</h3>
                        <br/>
                        <p>The CertaPet process is tailored towards connecting patients with qualified Mental Health Professionals.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-5 offset-md-1 mt-md-0 mt-5 text-center">
                    <div className="row">
                      <div className="col-12 col-md-5 img">
                        <Img fluid={data.fastIcon.childImageSharp.fluid} alt="" className="img-fluid lazyload mt-md-4 mt-sm-1 ml-md-0 ml-5" />
                      </div>
                      <div className="col-12 col-md-7 text-left px-5">
                        <h3 className="mt-md-4 mt-3">Fast</h3>
                        <br/>
                        <p>Our service takes much less time to complete compared to a day spent visiting a Mental Health Professional in person.</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-0 mt-md-5">

                  <div className="col-12 col-md-5 ml-md-5 mt-md-0 mt-5 text-center">
                    <div className="row">
                      <div className="col-12 col-md-5 img">
                        <Img fluid={data.discreetIcon.childImageSharp.fluid} alt="" className="img-fluid lazyload mt-md-4 mt-sm-1 ml-md-0 ml-5" />
                      </div>
                      <div className="col-12 col-md-7 text-left px-5">
                        <h3 className="mt-md-4 mt-3">Discreet</h3>
                        <br/>
                        <p>There’s no need to answer uncomfortable questions in person. Everything is handled privately online</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-5 offset-md-1 mt-md-0 mt-5 text-center">
                    <div className="row">
                      <div className="col-12 col-md-5 img">
                        <Img fluid={data.preventUnfairFeesIcon.childImageSharp.fluid} alt="" className="img-fluid lazyload mt-md-4 mt-sm-1 ml-md-0 ml-5" />
                      </div>
                      <div className="col-12 col-md-7 text-left px-5">
                        <h3 className="mt-md-4 mt-3">Prevent Unfair Fees</h3>
                        <br/>
                        <p>An ESA Letter will prevent you being charged thousands of dollars a year on pet deposits, pet housing fees, airline charges and other unjust costs.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Section>

          <Section bgColor="#e6f5fa" className="section-cards process py-0 px-0">
            <div className="row">
              <div className="col-10 col-md-10 text-center mt-5 mx-auto">
                <h2>We Make The Process Easier</h2>
                <br/>
                <p className="text-center">Why wouldn't you just get an ESA Letter on your own? While you<br className='d-none d-md-block'/> can, there are many hurdles you’d face including:</p>
                <br/>
                <div className="row mt-5 pb-md-5">
                  <div className="col-12 col-md-4 text-center mb-5 mb-md-auto">
                    <Card>
                      <Card.Body>
                        <Card.Title as="h3" className="mb-4 mt-4 px-md-5 px-2">Finding a Mental Health Professional who’s qualified to approve an ESA Letter for
                          your state.</Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-12 col-md-4 text-center mb-5 mt-md-5 mb-md-auto mt-md-auto">
                    <Card>
                      <Card.Body>
                        <Card.Title as="h3" className="mb-4 mt-4 px-md-5 px-2">Visiting them in person to be assessed. If they’re in another town or city this could take an entire day.</Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-12 col-md-4 text-center mb-5 mt-md-5 mb-md-auto mt-md-auto">
                    <Card>
                      <Card.Body>
                        <Card.Title as="h3" className="mb-4 mt-4 px-md-5 px-2">Having to answer deeply personal questions in-person that may make you feel uncomfortable.</Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
                <div className="row mt-md-2 pb-md-5 pb-4">
                  <div className="col-12 col-md-4 text-center mb-5 mb-md-auto">
                    <Card>
                      <Card.Body>
                        <Card.Title as="h3" className="mb-4 mt-4 px-md-5 px-2">Even with insurance, multiple consultations can cost you $300
                          or more.</Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-12 col-md-4 text-center mb-5 mt-md-5 mb-md-auto mt-md-auto">
                    <Card>
                      <Card.Body>
                        <Card.Title as="h3" className="mb-4 mt-4 px-md-5 px-2">You may have to visit them more than once, with no guarantee your request will be approved.</Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-12 col-md-4 text-center mb-5 mt-md-5 mb-md-auto mt-md-auto">
                    <Card>
                      <Card.Body>
                        <Card.Title as="h3" className="mb-4 mt-4 px-md-5 px-2">It may take a week or more for all the paperwork to be completed, putting your legal rights in limbo.</Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
              <div className="col-10 col-md-12 text-center mx-auto mb-5 mt-md-0">
                <div className="call-button my-lg-5">
                  {/*<a href={`${ctaUrl}&utm_campaign=getstarted&utm_content=cta3`} target="_blank">Get Started - Take Our Pre-Screening Now</a>*/}
                  <a href="/screening">Get Started - Take Our Pre-Screening Now</a>
                </div>
              </div>

            </div>
          </Section>

        </Layout>
      </>
    )
  }

}

export default (props) => {
  const data = useStaticQuery(graphql`
    query GetStarted {
      site {
        siteMetadata {
          certapetInfo {
            url {
              ctaUrl
            }
          }
        }
      } 
      blockquote: file(relativePath: { eq: "page_layout/icons/ico-blockquotes.png" }) {
        childImageSharp {
          fluid(maxWidth: 70, maxHeight: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      icoBenefits: file(relativePath: { eq: "page-icon/ico-benefits.png" }) {
        childImageSharp {
          fluid(maxWidth: 70, maxHeight: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      youngWomanCheckingHerIpadWithHerEmotionalSupportDogByHerSide: file(relativePath: { eq:"young-woman-checking-her-ipad-with-her-emotional-support-dog-by-her-side.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 680, maxHeight: 584) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      youngMenAtTheParkWithHisEmotionalSupportAnimal: file(relativePath: { eq:"young-men-at-the-park-with-his-emotional-support-animal.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 778, maxHeight: 654) {
            ...GatsbyImageSharpFluid
          }
        }
      } 
      easyProcessIcon: file(relativePath: { eq:"page-icon/easy.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 301) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      discreetIcon: file(relativePath: { eq:"page-icon/discreet.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 301) {
            ...GatsbyImageSharpFluid
          }
        }
      } 
      preventUnfairFeesIcon: file(relativePath: { eq:"page-icon/prevent-unfair-fees.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 301) {
            ...GatsbyImageSharpFluid
          }
        }
      } 
      fastIcon: file(relativePath: { eq:"page-icon/fast.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 301) {
            ...GatsbyImageSharpFluid
          }
        }
      } 
    }
  `)
  return (
    <GetStarted  {...props} data={data}/>
  )
};
